import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'querystring'
import { get, uniqBy } from 'lodash'
import { isParent, VIRTUAL } from '../../../utils'

import { ACCESS_TOKEN } from '../../../services/legacyAuth.service'
import { getItineraryData } from '../../../actions'

import { Activity, Header, Information, Participant } from './components'

import './itinerary.page.css'

/**
 * Itinerary
 */
class ItineraryPage extends Component {
  /**
   * componentDidMount
   */
  componentDidMount () {
    const { getItinerary, match, location, activityId } = this.props

    const id = activityId || get(match, 'params.id')
    const params = qs.parse(location && location.search && location.search.replace(/^\?*/, ''))
    const token = get(params, 'token') || localStorage.getItem(ACCESS_TOKEN) || null

    if (!token || !id) {
      return
    }

    if (get(params, 'token')) {
      localStorage.setItem(ACCESS_TOKEN, token)
    }

    getItinerary && getItinerary(id)

    document.body.classList.add('itinerary-page')
  }

  /**
   * Get Address Locations from Itineraries
   * @param itinerary
   * @param address
   * @return {{location: any}[]|Array}
   */
  getAddresses (itinerary, address) {
    if (!itinerary || !(typeof itinerary === 'object')) {
      return (address || [])
    }
    const parentAddress = Object.values(itinerary)
      .map((item) => {
        return Object.keys(item)
          .map((activity) => get(item, `${activity}[0].virtual`) === true
            ? { location: VIRTUAL }
            : { location: activity }
          )
      })

    return uniqBy([].concat(...parentAddress, address), 'location')
  }

  /**
   * Render
   * @return {XML}
   */
  render () {
    const { itinerary } = this.props
    const { address, title, start, end, participants, _itinerary, category } = itinerary
    return (
      <div className='page'>
        <Header title={title} />

        <main className='page_body'>
          <section className='pane pane--left'>
            <Information
              isParent={isParent(category)}
              address={isParent(category) ? this.getAddresses(_itinerary, address) : address}
              start={start}
              end={end} />
          </section>

          <section className='pane pane--right'>
            <Participant participants={participants} />
          </section>

          <section className='pane pane--bottom'>
            <Activity itinerary={_itinerary} />
          </section>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const itinerary = state.activity.itinerary.pdf
  return {
    status: itinerary.status,
    itinerary: itinerary.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getItinerary: bindActionCreators(getItineraryData, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ItineraryPage)

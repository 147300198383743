import moment from 'moment-timezone'
import jwtDecode from 'jwt-decode'
import { ACCESS_TOKEN, ACCESS_TOKEN_OAUTH } from '../../services/legacyAuth.service'
import { get, isEmpty, isObject } from 'lodash'
import { DEFAULT_Q4_ENTITY_ID, DEFAULT_TICKER } from './profile.const'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { oAuthHelper } from '../../services/oAuth.service'

const BETA_FEATURE_FLAGS = [
  {
    flag: 'beta_edw_targeting',
    path: '/targeting'
  },
  {
    flag: 'sp_search',
    path: '/search'
  }
]

/**
 * Gets active (or primary) ticker from profile
 * @param profile
 * @return {T}
 */
export const getActiveTicker = (profile) => {
  if (!profile || !profile._organization || !profile._organization.tickers) {
    return
  }
  const tickers = profile._organization.tickers
  const primaryTicker = tickers.find((ticker) => ticker.primary)
  const activeTicker = tickers.find((ticker) => ticker.active)

  return activeTicker || primaryTicker
}

/**
 * Get Tickers assigned to profile
 * @param profile
 * @returns {*}
 */
export const getTickers = (profile) => {
  if (!profile || !profile._organization || !profile._organization.tickers || !profile._organization.tickers.length) {
    return []
  }
  return profile._organization.tickers
}

/**
 * Is profile subscribed to a service
 * @param profile
 * @param serviceName
 * @returns {T | undefined}
 */
export const isSubscribed = (profile, serviceName) => {
  return ((profile && profile.services) || []).find((service) => {
    return (service.type === serviceName) && service.enabled
  })
}

/**
 * Determines if trial date is expired.
 * Returns true if trial is expired or expiry date is missing.
 * @return {Boolean}
 */
export const isTrialExpired = (profile) => {
  const expiresAt = profile && profile.type && profile.type.expire
  return expiresAt ? moment().utc().isAfter(moment(expiresAt)) : true
}

/**
 * Determines if a trial user acknowledged trial agreement
 * @param profile
 * @return {Boolean}
 */
export const isAcknowledgedTrial = (profile) => {
  if (profile && profile.type && profile.type.name !== 'trial') {
    return true
  }
  return !!(profile && profile.terms && profile.terms.acknowledged)
}

/**
 * Calculates an amount of days until trial expiry date
 * @param profile
 * @return {number}
 */
export const getDaysUntilTrialExpiry = (profile) => {
  const daysUntilExpiry = profile && profile.type && moment().utc().diff(profile.type.expire, 'days', true)
  return Math.abs(parseInt(daysUntilExpiry, 10))
}

/**
 * Determines if organization id in profile matches with organization id in jwt token
 * @param profile
 * @return {boolean}
 */
export const isOrganizationChanged = (profile) => {
  if (localStorage.getItem(ACCESS_TOKEN) && profile && profile._organization) {
    const token = jwtDecode(localStorage.getItem(ACCESS_TOKEN))
    const oldId = token && token._organization && token._organization._id
    const newId = profile && profile._organization && profile._organization._id

    return oldId !== newId
  }
}

/**
 * function to extract the organization id from oAuth jwt token
 * @param {object} oAuthToken 
 * @returns {string}  organization id
 */
export const getOrganizationIdFromOAuthToken = (oAuthToken) => {
  if (oAuthToken) {
    return get(jwtDecode(oAuthToken), 'https://q4desktop.com/_organization', '')
  }
}

/**
 * Function to get the organization id from oAuth helper
 * @returns {Promise<string | undefined>} organization id
 */
export const getOrganizationIdFromNewToken = () => {
  if (!oAuthHelper.useOAuthSDK()) return undefined
  const oAuthToken = localStorage.getItem(ACCESS_TOKEN_OAUTH)

  return get(jwtDecode(oAuthToken), 'https://q4desktop.com/_organization', '')
}

/**
 * Return an array of feature flags from user's profile
 * @param profile API response containing {type, data, status}, or profile.data directly
 * @return {(T | *)[]}
 */
export const getFeatureFlags = (profile) => {
  const features = get(profile, 'features') || get(profile, 'data.features') || []
  return features.map((feature) => feature?.type && feature)
}

/**
 * Returns true if beta feature is enabled in user profile and component is passing a particular flag
 * @param profile
 * @param flag
 * @return {boolean}
 */
export const isFeatureEnabled = (profile, flag) => {
  if (!profile || !flag) {
    return false
  }
  const features = getFeatureFlags(profile)
  return features?.find((feature) => feature?.type === flag)?.enabled || false
}

/**
 * Returns true if beta feature is enabled in user profile and user is attempting to access that feature's route
 * @param profile
 * @param location
 * @return {boolean}
 */
export const isFeatureEnabledByLocation = (profile, location) => {
  if (!profile || !location) {
    return false
  }
  const features = getFeatureFlags(profile)
  const { pathname = '', search = '' } = location

  return (BETA_FEATURE_FLAGS || []).some((betaFeature) => {
    const includesFlag = features?.find((feature) => feature.type === betaFeature.flag)?.enabled || false
    const includesPath = pathname.includes(betaFeature.path)
    const includesSearchParam = search.includes(betaFeature.betaParam)
    return (
      search
        ? includesFlag && includesPath && includesSearchParam
        : includesFlag && includesPath
    )
  })
}

/**
 * Returns true if 'tickerId' is not equal to DEFAULT_TICKER ('NonTrading')
 * @param tickerId
 * @return {boolean}
 */
export const isTradingTicker = (tickerId) => {
  if (!tickerId) {
    return false
  }
  return tickerId !== DEFAULT_TICKER
}

/**
 * Returns true if 'securityType' on Profile > Organization is equal to 'NonTrading'
 * @param profileData
 * @return {boolean}
 */
export const isNonTrading = (profileData) => {
  if (!profileData) {
    return false
  }

  const ticker = getActiveTicker(profileData)
  const securityType = get(ticker, 'securityType')
  return securityType === DEFAULT_TICKER
}

/**
  * Returns LaunchDarkly profile object
  * @param profile
  * @return {}
  */
export const buildLDProfile = (profile) => {
  if (!profile || !isObject(profile)) {
    return false
  }

  return {
    key: profile._id,
    firstName: profile.firstName,
    lastName: profile.lastName,
    email: profile.user,
    custom: {
      organization: profile._organization?._id,
      region: profile.region,
      accountType: profile.type?.name
    }
  }
}

/**
 * Returns value of flag from LaunchDarkly
 * @param flag
 * @return {boolean}
 */
export const getLaunchDarklyFlag = (flag) => {
  const ldClient = useLDClient()
  return ldClient.variation(flag)
}

/**
 * Returns q4_ticker_id (tickerId)
 * @param profile
 * @return {String}
 */
export const getQ4TickerId = (profile) => {
  if (!profile || isEmpty(profile)) {
    return ''
  }

  const ticker = getActiveTicker(profile)
  return isTradingTicker(ticker.q4_ticker_id) ? ticker.q4_ticker_id : DEFAULT_TICKER
}

/**
 * Returns q4_entity_id (securityId)
 * @param profile
 * @return {String}
 */
export const getQ4SecurityId = (profile) => {
  if (!profile || isEmpty(profile)) {
    return ''
  }

  const ticker = getActiveTicker(profile)
  return isTradingTicker(ticker.q4_ticker_id) ? ticker.q4_entity_id : DEFAULT_Q4_ENTITY_ID
}

/**
 * Returns _securityId (Legacy SecurityId)
 * @param profile
 * @return {String}
 */
export const getLegacySecurityId = (profile) => {
  if (!profile || isEmpty(profile)) {
    return DEFAULT_Q4_ENTITY_ID
  }

  const ticker = getActiveTicker(profile)
  return ticker._security || DEFAULT_Q4_ENTITY_ID
}

/**
 * Returns a boolean indicating if the splash screen should be displayed
 * @param isLoggedIn
 * @param profileLoaded
 * @param useOAuth
 * @param oAuthLoading
 * @param oAuthError
 * @param ldProfileInitiated
 * @param isLocalizationCookieAvailable
 * @return {boolean}
 */
export const canShowSplashScreen = (
  isLoggedIn,
  profileLoaded,
  useOAuth,
  oAuthLoading,
  oAuthError,
  ldProfileInitiated,
  isLocalizationCookieAvailable
) => {
  return useOAuth
    ? oAuthLoading || oAuthError || (isLoggedIn && (!profileLoaded || !isLocalizationCookieAvailable))
    : (isLoggedIn !== false) && ((!profileLoaded && !ldProfileInitiated) || !isLocalizationCookieAvailable)
}

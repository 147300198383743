import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { createToast } from '../../actions'
import { config } from '../../config'
import { useDispatchAction } from '../../hook'
import { useDispatch } from 'react-redux'
import { isLocalhost, IFRAME_MESSAGE_FROM_CHILD, getLocalStorageItem } from '../../utils'
import CCOAuthProviderWrapper from '../../components/ccOAuthProviderWrapper/ccOAuthProviderWrapper'
import EmailAppPage from '../../pages/emailApp/emailApp.page'

const EmailConnectionManagerContainer = () => {
  const { emailMfeUrl } = config || {}
  const isLocal = isLocalhost()
  const emailAppPath = 'standalone/email/index.html'
  const emailAppUrl = isLocal ? 'http://localhost:3000' : `${emailMfeUrl}/${emailAppPath}`
  const dispatch = useDispatch()
  const emailDisconnectURL = isLocal ? `${emailAppUrl}/#/disconnect` : `${emailAppUrl}#/disconnect`
  const handleCreateToast = useDispatchAction(createToast, dispatch)
  useEffect(() => {
    if (getLocalStorageItem(IFRAME_MESSAGE_FROM_CHILD.FINISHED_DISCONNECT)) {
      handleCreateToast({text: 'Email was disconnected successfully.'})
      localStorage.removeItem(IFRAME_MESSAGE_FROM_CHILD.FINISHED_DISCONNECT)
    } else if (getLocalStorageItem(IFRAME_MESSAGE_FROM_CHILD.FINISHED_CONNECT)) {
      handleCreateToast({text: 'Email was connected successfully.'})
      localStorage.removeItem(IFRAME_MESSAGE_FROM_CHILD.FINISHED_CONNECT)
    }
  }, [])

  return (
    <CCOAuthProviderWrapper>
      <EmailAppPage
        type="manage-connection"
        pageId='crm-manage-email-container'
        iframeId='crm-manage-email-viewport'
        iframeSrc={emailDisconnectURL}
      />
    </CCOAuthProviderWrapper>
  )
}

export default withRouter(EmailConnectionManagerContainer)

import './saved.container.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'

// actions
import {
  DELETE_SAVED_TARGETS_FAILURE,
  DELETE_SAVED_TARGETS_SUCCESS,
  deleteSavedTargets,
  getPaginatedSavedTargets,
  openModal,
  statusType,
  modalType
} from '../../../../actions'

// components
import SavedTargetsGrid from './grid/grid.component'
import TargetingSavedTabToolbar from './toolbar/toolbar.component'

import { getActivityAttendee } from '../../../../utils/activity'
import { config } from '../../../../config'

import { get } from 'lodash'
import { ENTITY_TYPE } from '../../../../utils/entity'
import { savedGridBulkActions } from '../../../../utils'
import { oAuthHelper } from '../../../../services/oAuth.service'
import { ACCESS_TOKEN_OAUTH } from '../../../../services/legacyAuth.service'

/**
 * Saved Targets Tab Container
 */
class SavedContainer extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.initialQuery = {
      search: '',
      type: 'All',
      sort: { property: 'create_date', direction: 'ASC' },
      page: 1,
      limit: 25
    }

    this.state = {
      query: this.initialQuery
    }
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this.getSavedTargets()
  }

  /**
   * Get Saved Targets list
   * @param query
   */
  getSavedTargets = (query = this.getQueryParams()) => {
    const { getSavedTargets } = this.props
    getSavedTargets(query)
  }

  /**
   * Handle query change event
   * @param query
   */
  handleQueryChange = (query) => {
    this.setState({
      query: {
        ...this.state.query,
        ...query
      }
    }, () => {
      this.getSavedTargets()
    })
  }

  /**
   * Handle exporting of saved target list
   */
  handleExport = async () => {
    const { token } = this.props

    const exportToken = oAuthHelper.useOAuthSDK()
      ? localStorage.getItem(ACCESS_TOKEN_OAUTH)
      : token?.data

    const query = {
      ...this.getQueryParams(),
      token: exportToken
    }

    window.open(`${config.apiUrl}/targeting/target/export/?${queryString.stringify(query)}`, '_self')
  }

  /**
   * Get query params for Saved Targets list
   * @returns {{}}
   */
  getQueryParams = () => {
    const queryParams = { ...this.state.query }

    if (queryParams.sort) {
      queryParams.sort = JSON.stringify([queryParams.sort])
    }

    return {
      ...queryParams
    }
  }

  /**
   * Handle sort change
   * @param property
   * @param direction
   */
  handleSortChange = ({ property, direction }) => {
    this.handleQueryChange({
      sort: { property, direction: direction.toUpperCase() }
    })
  }

  /**
   * Show error modal
   */
  handleDeleteFailure = () => {
    const { openModal } = this.props
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  /**
   * Delete saved targets
   * @param targets
   */
  removeSavedTargets = (targets) => {
    if (!targets || !targets.length) {
      return
    }

    const { deleteSavedTargets, getSavedTargets } = this.props
    const targetIds = targets.map((target) => target._id)
    deleteSavedTargets(targetIds).then((response) => {
      if (response.type === DELETE_SAVED_TARGETS_SUCCESS) {
        this.setState({
          query: {
            ...this.state.query,
            page: 1
          }
        }, () => {
          getSavedTargets(this.getQueryParams())
        })
      } else if (response.type === DELETE_SAVED_TARGETS_FAILURE) {
        this.handleDeleteFailure()
      }
    })
  }

  /**
   * Map saved target item to be compatible with activity
   * @param target
   * @return {{entityType: *, name: *, entityId: *, _id: *}}
   */
  getMappedActivityItems = (target) => {
    if (!target) {
      return
    }

    switch (target.entityType.toLowerCase()) {
      case 'contact':
        return {
          entityType: target.entityType,
          entityId: target.referenceItem.factset_person_id,
          name: target.referenceItem.full_name,
          jobs: target.referenceItem.jobs,
          _id: target.referenceItem._id
        }
      case 'institution':
        return {
          entityType: target.entityType,
          entityId: target.referenceItem.factset_entity_id,
          name: target.referenceItem.institution_name,
          _id: target.referenceItem._id
        }
      case 'fund':
        return {
          entityType: target.entityType,
          entityId: target.referenceItem.factset_fund_id,
          name: target.referenceItem.fund_name,
          _id: target.referenceItem._id
        }
      default:
        break
    }
  }

  /**
   * Add saved targets to new activity
   * @param targets
   */
  addSavedTargetsToActivity = (targets) => {
    if (!targets || !targets.length) {
      return
    }

    const { openModal } = this.props

    const mappedLinks = {
      links: targets
        .map(this.getMappedActivityItems)
        .map((target) => getActivityAttendee(target.entityType, target))
        .reduce((accumulator, currentValue) => {
          if (currentValue.links) {
            currentValue.links.forEach((link) => {
              // do not push duplicate links
              if (!(accumulator.find((accLink) => accLink.entity_id === link.entity_id))) {
                accumulator.push(link)
              }
            })
          }
          return accumulator
        }, [])
    }

    openModal({
      type: modalType.ACTIVITY_MODAL,
      props: {
        isParentOptionHidden: true,
        values: mappedLinks
      }
    })
  }

  /**
   * Open add to briefing book modal
   * @param targets
   */
  addSavedTargetsToBriefingbook = (targets) => {
    if (!targets || !targets.length) {
      return
    }

    this.props.openModal({
      type: modalType.ADD_TO_BRIEFING_BOOK_MODAL,
      props: {
        entities: targets.map(({ entityType, referenceItem: { _id, q4_entity_id, jobs } }) => {
          const type = (entityType || '').toLowerCase()
          const isContact = (type === ENTITY_TYPE.CONTACT)
          return {
            entityType: type,
            entityId: isContact ? _id : q4_entity_id,
            institutionId: isContact ? get(jobs, '[0].q4_entity_id', null) : null
          }})
      }
    })
  }

  /**
   * Handle bulk actions based on action id
   * @param actionId
   * @param selectedRows
   */
  handleBulkAction = (actionId, selectedRows) => {
    if (!actionId || !selectedRows || !selectedRows.length) {
      return
    }

    switch (actionId) {
      case savedGridBulkActions.DELETE_TARGETS:
        this.removeSavedTargets(selectedRows)
        break
      case savedGridBulkActions.ADD_TO_ACTIVITY:
        this.addSavedTargetsToActivity(selectedRows)
        break
      case savedGridBulkActions.ADD_TO_BRIEFING_BOOK:
        this.addSavedTargetsToBriefingbook(selectedRows)
        break
      default:
        break
    }
  }

  /**
   * Handle row click
   * Navigate to target entity page
   * @param rowData
   */
  handleRowClick = (rowData) => {
    if (!rowData || !rowData.entityType || !rowData.referenceItem) {
      return
    }

    const entityIdMap = {
      'institution': 'factset_entity_id',
      'fund': 'factset_fund_id',
      'contact': '_id'
    }
    const entityType = rowData.entityType.toLowerCase()
    const entityId = rowData.referenceItem[entityIdMap[entityType]]

    entityId && this.props.history.push(`/${entityType}/${entityId}`)
  }

  /**
   * Render
   * @returns {*}
   */
  render = () => {
    const { targets, counts, status } = this.props
    const { query } = this.state
    const noData = !targets || !targets.length
    const isLoading = status === statusType.IN_PROGRESS
    const type = query.type.toLowerCase()
    const initLoading = isLoading && noData

    return (
      <div className='targeting-page_tabs_tab targeting-page_tabs_tab--saved'>
        <TargetingSavedTabToolbar
          query={query}
          noData={noData}
          resultsTotalCounts={counts}
          handleQueryChange={this.handleQueryChange}
          handleExport={this.handleExport}
        />
        <SavedTargetsGrid
          data={targets}
          dataTotal={counts[type]}
          query={{
            page: query.page,
            limit: query.limit
          }}
          isLoading={isLoading}
          isGridReact={!initLoading}
          handleQueryChange={this.handleQueryChange}
          handleBulkAction={this.handleBulkAction}
          handleSortChange={this.handleSortChange}
          handleRowClick={this.handleRowClick}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const targetsData = state.targeting.paginatedSavedTargets
  return {
    token: state.token && state.token.data,
    status: targetsData.status,
    targets: targetsData.data,
    counts: targetsData.counts
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSavedTargets: bindActionCreators(getPaginatedSavedTargets, dispatch),
  deleteSavedTargets: bindActionCreators(deleteSavedTargets, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedContainer))

import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { getActiveTicker, getOrganizationIdFromNewToken, getTickers } from '../../utils'
import { getFromXigniteToStandard, formatStockPrice } from '../../utils/stock/stock.util'
import { format } from '../../utils/number.util'
import { statusType } from '../../actions/index'
import QuickSearch from './search/search.component'
import SecurityMenu from './securityMenu/securityMenu.component'
import ProfileMenu from './profileMenu/profileMenu.component'
import Notification from './notification/notification.component'
import ActivityFormContainer from '../activity/form/activityForm.container'

import './header.component.css'

class Header extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.state = {
      securityMenuAnchor: null,
      notificationsMenuAnchor: null,
      profileMenuAnchor: null,
      isActivityFormModalOpen: false,
      originalOrganizationId: null
    }
  }

  /**
   * Component Did Mount
   */
  componentDidMount () {
    const refreshInterval = 60000 // update every 10 mins
    this.timer = setInterval(this.loadStock.bind(this), refreshInterval)
    this.loadStock()
    this.setState({ originalOrganizationId: this.props.profile?.data?._organization?._id })
  }

  componentWillUnmount () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  loadStock = () => {
    const { tickerId, getStock } = this.props
    
     // For Q4 internal use - logout user if organization was switched
    const organizationId = getOrganizationIdFromNewToken()
    const profileOrgId = this.props.profile?.data?._organization?._id
      if (
        (
          profileOrgId && 
          organizationId && 
          this.state.originalOrganizationId
        )
        &&
        (
          profileOrgId !== organizationId || 
          profileOrgId !== this.state.originalOrganizationId || 
          organizationId !== this.state.originalOrganizationId
        )
      ) {
        window.location.href = '/logout?headless=true' // Force reload to clean up browser requests cache
      }

    
    tickerId && getStock(tickerId)
  };

  /**
   * Toggle Security Menu
   * @param event
   * @param toggle - override
   */
  toggleSecurityMenu = (event, toggle) => {
    const show = (typeof toggle !== 'undefined') ? toggle : !this.state.securityMenuAnchor
    const securityMenuAnchor = show ? event.currentTarget : null
    this.setState({ securityMenuAnchor })
  };

  /**
   * Get formatted stock value
   * @param stockQuote
   * @returns {String}
   */
  getFormattedStockValue = (stockQuote) => {
    const value = formatStockPrice(stockQuote.last, stockQuote.currency)
    return format(Math.abs(value), 2)
  };

  /**
   * Get Stock direction
   * @param stockQuote
   * @returns {string}
   */
  getDirection = (stockQuote) => {
    const change = stockQuote && (stockQuote.last - stockQuote.lastClose)
    return (change > 0) ? 'up' : (change < 0) ? 'down' : ''
  };

  /**
   * Toggle Profile Menu
   * @param event
   * @param toggle - override
   */
  toggleProfileMenu = (event, toggle) => {
    const show = (typeof toggle !== 'undefined') ? toggle : !this.state.profileMenuAnchor
    const profileMenuAnchor = show ? event.currentTarget : null
    this.setState({ profileMenuAnchor })
  };

  /**
   * Toggle Notification Menu
   * @param event
   * @param toggle - override
   */
  toggleNotificationMenu = (event, toggle) => {
    const show = (typeof toggle !== 'undefined') ? toggle : !this.state.notificationsMenuAnchor
    const notificationsMenuAnchor = show ? event.currentTarget : null

    this.setState({ notificationsMenuAnchor }, () => {
      show && this.markUnreadNotifications()
    })
  };

  /**
   * Check unread notifications
   */
  markUnreadNotifications = () => {
    const { markReadNotifications, notifications } = this.props
    const unreadIds = (notifications.data || [])
      .filter((n) => n._id && !n.read)
      .map((n) => n._id)
    const unreadProgressIds = (notifications.data || []).filter((n) => n.id && !n.read)

    if (unreadIds.length) {
      markReadNotifications(unreadIds, true)
    } else if (unreadProgressIds.length) {
      markReadNotifications([], false)
    }
  };

  onLogActivity = () => {
    this.setState({
      isActivityFormModalOpen: true
    })
  };

  onLogActivityClose = () => {
    this.setState({
      isActivityFormModalOpen: false
    })
  };

  /**
   * Render Header
   * @returns {XML}
   */
  render () {
    const { auth, history, location, profile, stock, getStock, shared, sendMail, suggestions, getQuickSearch, setActiveTicker, createToast, openModal, notifications, downloadFile, clearQuickSearch } = this.props
    const { securityMenuAnchor, notificationsMenuAnchor, profileMenuAnchor, isActivityFormModalOpen } = this.state

    if (history && history.location && history.location.pathname === '/login') {
      return (null)
    }

    const activeTicker = getActiveTicker(profile && profile.data)
    const stockQuote = (stock && stock.status === statusType.SUCCESS) ? stock.data : null
    const isUnread = notifications && notifications.data && notifications.data.length && notifications.data.filter((n) => !n.read).length
    const isSingleTicker = getTickers(profile && profile.data).length === 1

    return (
      <Fragment>
        <header id='app-header' className='app-header'>
          <QuickSearch
            loading={suggestions.status === statusType.IN_PROGRESS}
            suggestions={suggestions.data}
            getQuickSearch={getQuickSearch}
            profile={profile}
            clearSearch={clearQuickSearch}
            location={location}
            history={history} />
          <div className='app-header_actions'>
            <button
              className={`app-header_button ${isSingleTicker ? 'app-header_button--single-security' : 'app-header_button--security'} ${securityMenuAnchor ? 'app-header_button--active' : ''}`}
              aria-owns={securityMenuAnchor ? 'security-menu' : null}
              aria-haspopup='true'
              disabled={isSingleTicker}
              onClick={this.toggleSecurityMenu}
            >
              {activeTicker && activeTicker.symbol}<span
                className='app-header_button-divider'
              >|
              </span>{activeTicker && getFromXigniteToStandard(activeTicker.exchange)}
              <div className='app-header_button_stock'>
                {stockQuote && this.getFormattedStockValue(stockQuote)}
                {
                  !isSingleTicker && <span
                    className={`app-header_button_indicator app-header_button_indicator--${this.getDirection(stockQuote)}`}
                  />
                }
              </div>
            </button>
            <SecurityMenu
              onClose={(e) => this.toggleSecurityMenu(e, false)}
              anchorEl={securityMenuAnchor}
              profile={profile}
              stockQuote={stockQuote}
              getStock={getStock}
              setActiveTicker={setActiveTicker}
              history={history}
            />

            <button
              onClick={this.onLogActivity}
              className='app-header_button app-header_button--activity'
            >
              <i className='app-header_icon q4i-activity-2pt' />
              <div className='tooltip tooltip--bottom-right'>Log Activity</div>
            </button>

            <button
              className={`app-header_button ${notificationsMenuAnchor ? 'app-header_button--active' : ''}`}
              aria-owns={notificationsMenuAnchor ? 'notification-modal' : null}
              aria-haspopup='true'
              onClick={this.toggleNotificationMenu}
            >
              {isUnread ? <div className='app-header_button-badge' /> : null}
              <i className='app-header_icon q4i-notifications-2pt' />
            </button>
            <Notification
              onClose={(e) => this.toggleNotificationMenu(e, false)}
              anchorEl={notificationsMenuAnchor}
              notifications={notifications}
              downloadFile={downloadFile}
              history={history}
            />

            <button
              className={`app-header_button ${profileMenuAnchor ? 'app-header_button--active' : ''}`}
              aria-owns={profileMenuAnchor ? 'profile-menu' : null}
              aria-haspopup='true'
              onClick={this.toggleProfileMenu}
            >
              <i className='app-header_icon icon q4i-contact-2pt' />
            </button>
            <ProfileMenu
              onClose={(e) => this.toggleProfileMenu(e, false)}
              anchorEl={profileMenuAnchor}
              profile={profile} shared={shared} sendMail={sendMail}
              createToast={createToast}
              openModal={openModal}
              auth={auth}
            />
          </div>
        </header>

        {isActivityFormModalOpen && (
          <ActivityFormContainer onClose={this.onLogActivityClose} />
        )}

      </Fragment>
    )
  }
}

export default withRouter(Header)

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import activity from './activity'
import addressBook from './addressBook'
import aiScore from './aiScore'
import briefingBook from './briefingBook'
import calendar from './calendar'
import crmEmail from './crmEmail/crmEmail.reducer'
import chart from './chart'
import contact from './contact'
import corporateParticipant from './corporateParticipant'
import dashboard from './dashboard'
import deal from './deal/deal.reducer'
import deals from './deal/deals.reducer'
import entityDeals from './deal/entityDeals.reducer'
import entitySummary from './entitySummary/entitySummary.reducer'
import estimates from './estimates'
import event from './event'
import fund from './fund'
import institution from './institution'
import news from './news'
import notifications from './notifications/notifications.reducer'
import ownership from './ownership'
import login from './login/login.reducer'
import peer from './peer/peer.reducer'
import peerConfig from './peerConfig/peerConfig.reducer'
import pipeline from './pipeline/pipeline.reducer'
import profile from './profile/profile.reducer'
import purchasingPower from './purchasingPower'
import resetPasswordEmail from './oAuth'
import report from './report'
import search from './search'
import searchEntity from './search/entity.reducer'
import sencha from './sencha/sencha.reducer'
import shared from './shared'
import stage from './stage/stage.reducer'
import stock from './stock'
import security from './security'
import tag from './tag'
import targeting from './targeting'
import tearsheet from './tearsheet'
import toast from './toast/toast.reducer'
import token from './token/token.reducer'
import ui from './ui'
import widgetReducer from './widget'
import history from '../utils/history'

const appReducer = combineReducers({
  activity,
  addressBook,
  aiScore,
  briefingBook,
  calendar,
  crmEmail,
  chart,
  contact,
  corporateParticipant,
  dashboard,
  deal,
  deals,
  entityDeals,
  entitySummary,
  estimates,
  event,
  fund,
  institution,
  news,
  notifications,
  ownership,
  login,
  peer,
  peerConfig,
  pipeline,
  profile,
  purchasingPower,
  resetPasswordEmail,
  report,
  search,
  searchEntity,
  sencha,
  shared,
  stage,
  stock,
  security,
  tag,
  targeting,
  tearsheet,
  toast,
  token,
  ui,
  router: connectRouter(history),
  widget: widgetReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer

import React, { Component } from 'react'
import { Menu, MenuItem, Grow } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'
import { Feedback } from '../../../components'
import ChangePassword from './changePassword/changePassword.component'
import { config } from '../../../config'
import './profileMenu.component.css'
import { LOCAL_AUTH_MODE, oAuthHelper } from '../../../services/oAuth.service'
import ResetPassword from './resetPassword/resetPassword'
import { CRM_MANAGE_EMAIL_ROUTE, getIsBrowser, getLocalStorageItem } from '../../../utils'
import { launchDarklyHelper, LD_FEATURE_FLAGS } from '../../../services/launchDarkly.service'

class ProfileMenu extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      isChangePasswordModal: false
    }
  }

  /**
   * Show Feedback Modal
   */
  showFeedbackModal = () => {
    const { openModal, shared, sendMail, createToast } = this.props

    openModal({
      component: ({ onClose }) => (
        <Feedback
          visible
          onClose={onClose}
          mail={shared.mail}
          sendMail={sendMail}
          createToast={createToast}
        />
      )
    })
  }

  /**
   * Show Change Password Modal
   */
  showChangePasswordModal = () => {
    const { openModal, auth, createToast, profile } = this.props

    oAuthHelper.useOAuthSDK()
      ? openModal({
          component: ({ onClose }) => (
            <ResetPassword
              visible
              profile={profile}
              onClose={onClose}
              createToast={createToast}
            />
          )
        })
      : openModal({
          component: ({ onClose }) => (
            <ChangePassword
              visible
              onClose={onClose}
              auth={auth}
              createToast={createToast}
            />
          )
        })
  }

  /**
   * On Menu Item Click
   * @param event
   * @param key
   */
  onClick = (event, key) => {
    const { onClose, history } = this.props

    switch (key) {
      case 'team':
        history.push('/team')
        break
      case 'feedback':
        this.showFeedbackModal()
        break
      case 'help_center':
        window.open(`${config.helpUrl}/index.html`)
        break
      case 'change_password':
        this.showChangePasswordModal()
        break
      case 'connect_email':
        history.push(CRM_MANAGE_EMAIL_ROUTE.MANAGE_CONNECTION)
        break
      case 'logout':
        history.push('/logout')
        break
      default:
        break
    }

    onClose()
  }

  /**
   * Render Profile Menu
   * @returns {XML}
   */
  render () {
    const { anchorEl, onClose, profile, flags } = this.props

    if (!profile || !profile.data) {
      return null
    }

    const { firstName, lastName, title } = profile.data

    const browser = getIsBrowser()
    const isCompatibleBrowserForBulkEmail = browser.isChrome || browser.isEdge || browser.isEdgeChromium

    const q4IdpFeatureFlag = launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_Q4_IDP)
    const localAuthMode = getLocalStorageItem(LOCAL_AUTH_MODE.KEY)    
    const isIdpAuth = localAuthMode === LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP
    const isSsoAuth = localAuthMode === LOCAL_AUTH_MODE.VALUE.DESKTOP_AUTHO
    const disableChangePasswordItem =  oAuthHelper.useOAuthSDK() && (isIdpAuth || q4IdpFeatureFlag) && !isSsoAuth;

    const changePasswordItem = !disableChangePasswordItem ? [{
      key: 'change_password',
      title: 'Change Password',
      icon: 'q4i-lock-2pt'
    }] : [];

    const menuItems = [
      { key: 'team', title: 'My Q4 Team', icon: 'q4i-team-2pt' },
      { key: 'feedback', title: 'Leave Feedback', icon: 'q4i-feedback-2pt' },
      {
        key: 'help_center',
        title: 'Help Center',
        icon: 'q4i-question-mark-2pt'
      },
      ...changePasswordItem,
      ...(flags[LD_FEATURE_FLAGS.CRM_EMAIL] &&
      flags[LD_FEATURE_FLAGS.CRM_EMAIL_MANAGE_MENU_OPTION] &&
      isCompatibleBrowserForBulkEmail
        ? [
            {
              key: 'connect_email',
              title: 'Manage Email Connections',
              icon: 'q4i-mail-2pt'
            }
          ]
        : []),
      { key: 'logout', title: 'Logout', icon: 'q4i-logout-2pt' }
    ]

    return (
      <div className='profile-menu-component'>
        <Menu
          id='profile-menu'
          className='profile-menu-mask'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
          classes={{ paper: 'profile-menu' }}
          elevation={1}
          marginThreshold={0}
          TransitionComponent={Grow}
          style={{ transformOrigin: '0 0 0' }}
        >
          <div className='profile-menu_profile'>
            <div className='profile-menu_profile_name'>
              {firstName} {lastName}
            </div>
            <div className='profile-menu_profile_title'>{title}</div>
          </div>

          {menuItems.map(item => {
            const { key, title, icon } = item
            return (
              <MenuItem
                key={key}
                classes={{
                  root: 'profile-menu_item'
                }}
                onClick={e => this.onClick(e, key)}
                disableRipple
              >
                <i className={`icon ${icon}`} /> {title}
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }
}

ProfileMenu.propTypes = {
  profile: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

export default withRouter(withLDConsumer()(ProfileMenu))

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createToast, setCrmEmailMounted } from '../../actions'
import { crmEmailRoutes } from '../../data/routes/crmEmail.data'
import { useDispatchAction, useTypedSelector } from '../../hook'
import useBeforeUnload from '../../hook/useBeforeUnload.hook'
import { CRM_EMAIL_ROUTE, isLocalhost, reactToCrmEmailPath } from '../../utils'
import CCOAuthProviderWrapper from '../../components/ccOAuthProviderWrapper/ccOAuthProviderWrapper'
import CrmEmailIFrame from "../../components/crmEmailIFrame/crmEmailIFrame"
import { EMAIL_APP_NAME, IFRAME_MESSAGE_FROM_CHILD, IFRAME_MESSAGE_TO_CHILD } from '../../utils/email/email.const'
import { config } from '../../config'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

const EmailAppPage = (props) => {
  const {
    pageId = '',
    iframeId = '',
    iframeSrc = null,
  } = props

  // #region Hooks
  /** @type {import('react').Ref<HTMLIFrameElement>} */
  const crmEmailRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { user, signinSilent, isAuthenticated } = useAuth()
  useBeforeUnload();
  // #endregion

  // #region URL Setup
  const { emailMfeUrl } = config || {}
  const isLocal = isLocalhost()
  const emailAppDomain = isLocal ? 'http://localhost:3000' : emailMfeUrl
  // #endregion

  // #region MapDispatchToProps
  const handleCrmEmailMounted = useDispatchAction(setCrmEmailMounted, dispatch)
  const handleCreateToast = useDispatchAction(createToast, dispatch)
  // #endregion

  // #region MapStateToProps
  const profile = useTypedSelector((state) => state.profile.data)
  const recipients = useTypedSelector((state) => state.crmEmail?.recipients)
  const isCrmEmailMounted = useTypedSelector((state) => state.crmEmail?.mounted)
  // #endregion

  // #region States
  const [prevLocation, setPrevLocation] = useState('')
  const [childPathOnParent, setChildPathOnParent] = useState('')
  const [initCalled, setInitCalled] = useState(false)
  // #endregion
  
  const crmEmailPath = reactToCrmEmailPath(location?.pathname)
  const active = !!crmEmailPath
  const preventReload = childPathOnParent === '/' && window.location.pathname === '/email'
  useBeforeUnload(preventReload)

  const handleMessage = useCallback((event) => {
    const { source, type, params } = event?.data || {}
    if (source === EMAIL_APP_NAME) {
      switch (type) {
        case IFRAME_MESSAGE_FROM_CHILD.INIT:
          setInitCalled(true)
          break
        case IFRAME_MESSAGE_FROM_CHILD.MOUNTED:
          handleCrmEmailMounted(true)
          break
        case IFRAME_MESSAGE_FROM_CHILD.UNMOUNTED:
          handleCrmEmailMounted(false)
          break
        case IFRAME_MESSAGE_FROM_CHILD.UPDATE_CHILD_PATH_ON_PARENT: {
          const { path } = params
          setChildPathOnParent(path)
          break
        }
        case IFRAME_MESSAGE_FROM_CHILD.FINISHED_CONNECT:
          // Check if user come from /email page
          if (history.location.pathname === CRM_EMAIL_ROUTE.EMAIL) {
            history.push(CRM_EMAIL_ROUTE.EMAIL);
          }
          // If user come from email-manage-connect page or any other page
          else {
            localStorage.setItem(IFRAME_MESSAGE_FROM_CHILD.FINISHED_CONNECT, 'true')
            history.go()
          }

          history.go()
          break
        case IFRAME_MESSAGE_FROM_CHILD.REFRESH:
          history.go()
          break
        case IFRAME_MESSAGE_FROM_CHILD.FINISHED_DISCONNECT:
          localStorage.setItem(IFRAME_MESSAGE_FROM_CHILD.FINISHED_DISCONNECT, 'true')
          history.go()
          break;
        case IFRAME_MESSAGE_FROM_CHILD.GO_TO_PREVIOUS_PAGE:
          history.go(-1)
          break
        case IFRAME_MESSAGE_FROM_CHILD.SHOW_SUCCESS_TOAST: {
          const { text } = params || {}
          handleCreateToast({ text })
          break
        }
        default:
          break
      }
    }
  }, [history, handleCreateToast, handleCrmEmailMounted])

  const sendIframeMessageToEmailApp = useCallback((type, payload) => {
    crmEmailRef?.current?.contentWindow?.postMessage({ source: 'desktop', type, payload }, emailAppDomain)
  }, [emailAppDomain])

  const sendRecipientsToEmailApp = useCallback(() => {
    const payload = { recipients }
    sendIframeMessageToEmailApp(IFRAME_MESSAGE_TO_CHILD.UPDATE_RECIPIENTS, payload)
  }, [recipients, sendIframeMessageToEmailApp])

  const sendDesktopUserIdToEmailApp = useCallback(() => {
    const payload = { desktopUserId: profile._id }
    sendIframeMessageToEmailApp(IFRAME_MESSAGE_TO_CHILD.UPDATE_DESKTOP_USER_ID, payload)
  }, [profile, sendIframeMessageToEmailApp])

  const updateParentUrlOnEmailApp = useCallback(() => {
    const payload = { url: prevLocation.pathname }
    sendIframeMessageToEmailApp(IFRAME_MESSAGE_TO_CHILD.UPDATE_PARENT_PATH_ON_CHILD, payload)
  }, [prevLocation.pathname, sendIframeMessageToEmailApp])

  const sendDesktopFFToEmailApp = useCallback(() => {
    const payload = { desktopFF: { useRebrandedUi: launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_REBRANDED_UI) }}
    sendIframeMessageToEmailApp(IFRAME_MESSAGE_TO_CHILD.UPDATE_DESKTOP_FF, payload)
  }, [launchDarklyHelper, sendIframeMessageToEmailApp])

  const handleNoRecipients = useCallback(() => {
    const { location: { pathname } } = history

    if (pathname === CRM_EMAIL_ROUTE.EMAIL && !recipients.length) {
      history.goBack()
    }
  }, [history, recipients.length])

  const historyListener = useCallback((location) => {
    setPrevLocation(location)
    updateParentUrlOnEmailApp()
  }, [updateParentUrlOnEmailApp])

  useEffect(() => {
    if (isCrmEmailMounted) {
      sendRecipientsToEmailApp()
      sendDesktopUserIdToEmailApp()
      updateParentUrlOnEmailApp()
    }
  }, [isCrmEmailMounted, sendRecipientsToEmailApp, updateParentUrlOnEmailApp])

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [handleMessage])

  useEffect(() => {
    return () => {
      updateParentUrlOnEmailApp()
      handleCrmEmailMounted(false)
    }
  }, [updateParentUrlOnEmailApp])

  useEffect(() => {
    handleNoRecipients()
    const unlisten = history.listen(historyListener)

    return () => {
      unlisten()
    }
  }, [handleNoRecipients, history, historyListener])

  useEffect(async () => {
    if (!isAuthenticated && initCalled) {
      const newUser = await signinSilent()

      if (newUser) {
        sendIframeMessageToEmailApp(IFRAME_MESSAGE_TO_CHILD.UPDATE_AUTH0_USER, {
          accessToken: newUser.access_token,
          user: newUser
        })
      }
    } else if (user) {
      sendIframeMessageToEmailApp(IFRAME_MESSAGE_TO_CHILD.UPDATE_AUTH0_USER, {
        accessToken: user?.access_token,
        user
      })
    }

    sendDesktopFFToEmailApp()
  }, [initCalled])

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
  }, [handleMessage])
  // #endregion

  const isFullScreen = (crmEmailPath) => {
    const route = crmEmailRoutes.find((route) => route.crmEmailPath === crmEmailPath)
    return !!(route && route.fullscreen)
  }

  const activeClassName = active ? `${iframeId}--active` : ''
  const fullscreenClassName = isFullScreen(crmEmailPath) ? `${iframeId}--fullscreen` : ''
  const baseClassName = [iframeId, activeClassName, fullscreenClassName].join(' ')

  return (
    <CCOAuthProviderWrapper>
      <CrmEmailIFrame
        id={pageId}
        baseClassName={baseClassName}
        iframeId={iframeId}
        iframeRef={crmEmailRef}
        {...iframeSrc && { iframeSrc }}
      />
    </CCOAuthProviderWrapper>
  )
}

export default EmailAppPage;

import React from 'react'
import { withRouter } from 'react-router-dom'
import CCOAuthProviderWrapper from '../../components/ccOAuthProviderWrapper/ccOAuthProviderWrapper'
import { config } from '../../config'
import EmailAppPage from '../../pages/emailApp/emailApp.page'
import { isLocalhost } from '../../utils'

const EmailComposerContainer = () => {
  const { emailMfeUrl } = config || {}
  const isLocal = isLocalhost()
  const emailAppPath = 'standalone/email/index.html'
  const emailAppUrl = isLocal ? 'http://localhost:3000' : `${emailMfeUrl}/${emailAppPath}`
  return (
    <CCOAuthProviderWrapper>
      <EmailAppPage
        type='composer'
        pageId='crm-email-container'
        iframeId='crm-email-viewport'
        iframeSrc={emailAppUrl}
      />
    </CCOAuthProviderWrapper>
  )
}

export default withRouter(EmailComposerContainer)

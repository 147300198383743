import { launchDarklyHelper, LD_FEATURE_FLAGS } from "../../services/launchDarkly.service"
import { ACCESS_TOKEN_OAUTH } from "../../services/legacyAuth.service"
import { oAuthHelper, LOCAL_AUTH_MODE } from "../../services/oAuth.service"
import { getLocalStorageItem } from "../localStorage.util"

/**
 * Set SSO/Legacy/Q4IDP as LocalAuthMode and
 * @param { string } authMode
 * @param { function } getLogoutContainer
 * @param { object } auth
 */
export const setAuthModeSSO = (authMode, getLogoutContainer, auth) => {
  const shouldUseSSO = authMode === LOCAL_AUTH_MODE.VALUE.DESKTOP_AUTHO || authMode === LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP
  if (!shouldUseSSO && oAuthHelper.useOAuthSDK()) {
    oAuthHelper.setUseOAuthSDK(false)
  }

  switch (authMode) {
    case LOCAL_AUTH_MODE.VALUE.DESKTOP_LEGACY:
      localStorage.setItem(LOCAL_AUTH_MODE.KEY, LOCAL_AUTH_MODE.VALUE.DESKTOP_LEGACY)
      break
    case LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP:
      localStorage.setItem(LOCAL_AUTH_MODE.KEY, LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP)
      break
    default:
      localStorage.setItem(LOCAL_AUTH_MODE.KEY, LOCAL_AUTH_MODE.VALUE.DESKTOP_AUTHO)
      break
  }

  // Logout current user if logged in
  return getLogoutContainer(auth)
}

/**
 *  Get the active token based on the auth mode (SSO/Legacy) 
 * @param {string | object | undefined} legacyToken 
 * @returns  {Promise<string>} access token
 */
export const getActiveToken = async (legacyToken) => {
  const legacyAccessToken = legacyToken?.data || legacyToken
  return oAuthHelper.useOAuthSDK()
    ? localStorage.getItem(ACCESS_TOKEN_OAUTH)
    : legacyAccessToken
}

export const getCurrentAuthMode = () => {
  // Default to rely on LD
  const q4IdpFeatureFlag = launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_Q4_IDP)

  let authenticationMode = q4IdpFeatureFlag
    ? LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP
    : LOCAL_AUTH_MODE.VALUE.DESKTOP_AUTHO;

  // Overwrite LD with local authentication mode
  const localAuthMode = getLocalStorageItem(LOCAL_AUTH_MODE.KEY)

  if (localAuthMode) authenticationMode = localAuthMode

  return authenticationMode
}


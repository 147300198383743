import { config } from '../../config'
import { ACCESS_TOKEN } from '../../services/legacyAuth.service'

export const REMOVE_REPORT_BUILDER_PAGES_THUMBNAIL = 'REMOVE_REPORT_BUILDER_PAGES_THUMBNAIL'

/*eslint-disable */
/**
 * getReportPageThumbnail
 */
export const getReportPageThumbnail = async (params) => {
  try {
    const response = await fetch(`${config.apiUrl}/report/thumbnail`, {
      method: 'POST',
      headers: {
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        Accept: 'image/jpeg'
      },
      body: JSON.stringify(params),
      json: true
    })

    if (response.status !== 200) {
      return null
    }

    return response.blob()
  } catch (err) {
    return null
  }
}
/*eslint-disable enable */

/**
 * Removes thumbnail associated with a page
 * @param pageId
 */
export const removeReportPageThumbnail = (pageId) => (dispatch) => dispatch({
  type: REMOVE_REPORT_BUILDER_PAGES_THUMBNAIL,
  payload: { pageId }
})

import React from 'react'
import { config, LDConfig } from './config'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { LicenseManager } from 'ag-grid-enterprise'
import history from './utils/history'
import Root from './pages/index'
import configureStore from './store/configure.store'
import theme from './resources/theme/q4.theme'
import { AuthProvider } from 'react-oidc-context'
import { ApolloProvider } from '@apollo/react-hooks'
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import { getOAuthConfig } from './hook/useOAuthConfig.hook'
import client from './middleware/graphql.middleware'

import { oAuthHelper, LOCAL_AUTH_MODE } from './services/oAuth.service'
import { launchDarklyHelper, LD_FEATURE_FLAGS } from './services/launchDarkly.service'
import { getLocalStorageItem, isLocalhost, setFavicon, updateCurrencyLocalStorage } from './utils'
import { overrideLogWarn } from './utils/dev.util'
import './resources/styles/global.css'
import CCOAuthProviderWrapper from './components/ccOAuthProviderWrapper/ccOAuthProviderWrapper'

LicenseManager.setLicenseKey(config.agGridKey)

async function init () {
  const store = await configureStore()
  const LDProvider = await asyncWithLDProvider(LDConfig)

  // Suppress unnecessary warnings on localhost
  if (isLocalhost()) {
    console.warn = overrideLogWarn
  }

  // Handle BulkEmail Silent Redirect (based on hidden iframe)
  if (window.self !== window.top && window.location.pathname === '/email') {
    return render(
      <CCOAuthProviderWrapper>
        {console.log("Authorize BulkEmail")}
      </CCOAuthProviderWrapper>,
      document.getElementById('root')
    )
  }

  const LaunchDarklyWrapper = () => {
    const ldClient = useLDClient()
    launchDarklyHelper.setLocalFlags(ldClient.allFlags())

    ldClient.on('initialized', () => {
      launchDarklyHelper.setLocalFlags(ldClient.allFlags())
      updateCurrencyLocalStorage(store?.getState()?.profile?.data)
    })

    ldClient.on('change', () => {
      setTimeout(() => {
        launchDarklyHelper.setLocalFlags(ldClient.allFlags())
        updateCurrencyLocalStorage(store?.getState()?.profile?.data)
      }, 400)
    })

    ldClient.on('change', () => {
      setTimeout(() => {
        launchDarklyHelper.setLocalFlags(ldClient.allFlags())
        updateCurrencyLocalStorage(store?.getState()?.profile?.data)
      }, 400)
    })

    // Default to rely on LD
    const q4IdpFeatureFlag = launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_Q4_IDP)
    let authenticationMode = q4IdpFeatureFlag 
      ? LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP 
      : LOCAL_AUTH_MODE.VALUE.DESKTOP_AUTHO;

    oAuthHelper.setUseOAuthSDK(true)

    // Overwrite LD with local authentication mode
    const localAuthMode = getLocalStorageItem(LOCAL_AUTH_MODE.KEY)    
    
    if (localAuthMode) authenticationMode = localAuthMode

    if (window.location.pathname === '/use-q4idp-login') authenticationMode = LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP
    if (window.location.pathname === '/use-legacy-login') authenticationMode = LOCAL_AUTH_MODE.VALUE.DESKTOP_LEGACY

    if (authenticationMode == LOCAL_AUTH_MODE.VALUE.DESKTOP_LEGACY) {
      oAuthHelper.setUseOAuthSDK(false)
    }

    // Set favicon based on Q4 logo version
    setFavicon()

    if (!oAuthHelper.useOAuthSDK()) return <Root store={store} />

    const authConfig = getOAuthConfig(authenticationMode);

    return (
      <AuthProvider {...authConfig}>
        <Root store={store} />
      </AuthProvider>
    )
  }

  render(
    <LDProvider>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <LaunchDarklyWrapper />
            </MuiThemeProvider>
          </ConnectedRouter>
        </Provider>
      </ApolloProvider>
    </LDProvider>,
    document.getElementById('root')
  )
}

init()

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from '../../..'
import { resetPasswordEmailState, sendResetPasswordEmail, statusType } from '../../../../actions'
import { useDispatchAction, useTypedSelector } from '../../../../hook'

/**
 * oAuth Reset Password Modal
 * @param props
 */
function ResetPassword ({ onClose, createToast, visible, profile }) {
  const dispatch = useDispatch()
  const handleResetPasswordEmail = useDispatchAction(sendResetPasswordEmail, dispatch)
  const handleResetState = useDispatchAction(resetPasswordEmailState, dispatch)
  const postResponse = useTypedSelector((state) => state.resetPasswordEmail)

  const showError = () => {
    createToast({ text: 'Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.', type: 'error' })
    handleResetState()
    onClose()
  }

  const showSuccess = () => {
    createToast({ text: 'We sent you an email that contains a link to reset your password.' })
    handleResetState()
    onClose()
  }

  const handleSubmit = () => {
    const email = profile?.data?.user
    if (!email) {
      showError()
      return
    }
    handleResetPasswordEmail({ email: email })
  }

  useEffect(() => {
    if (postResponse?.status === statusType.SUCCESS) {
      showSuccess()
    }
    if (postResponse?.status === statusType.ERROR) {
      showError()
    }
  }, [postResponse])

  const submitButtonsDisabled = postResponse 
    ? postResponse.status === statusType.IN_PROGRESS 
    : false

  return (
    <Modal
      visible={visible}
      title='Change your password'
      containerWidth={500}
      contentHeight={10}
      onClose={onClose}
      footerButtons={[{
        label: 'Cancel',
        ui: 'shaded',
        onClick: onClose
      }, {
        label: 'Send Email',
        ui: 'citrus',
        disabled: submitButtonsDisabled,
        onClick: handleSubmit
      }]}
    >
      <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
        <p> We will send you an email to reset your password. </p>
      </form>
    </Modal>
  )
}

export default ResetPassword

import React, { Component } from 'react'
import { Modal } from '../../../components'
import { FormikTextInput } from '../../shared'
import { withFormik } from 'formik'
import * as Yup from 'yup'

/**
 * Forgot password component
 */
class _ForgotPasswordModal extends Component {

  /**
   * Close modal
   */
  closeModal = () => {
    const { onClose, setStatus, setErrors, setValues } = this.props
    setErrors({})
    setValues({ email: '' })
    setStatus({})
    onClose()
  }

  /**
   * render error message
   * @param errors
   * @returns {*}
   */
  renderError = (errors) => {
    if (!errors) {
      return null
    }
    const error = errors && errors.serverError ? errors.serverError : Object.values(errors)[0]
    return <div className='modal_error'>{error}</div>
  }

  /**
   * Render
   * @return {XML}
   */
  render () {
    const { visible, isSubmitting, handleSubmit, status, errors } = this.props

    const showFeedback = status && status.success
    const buttons = showFeedback ? [{
      label: 'OK',
      ui: 'citrus',
      onClick: this.closeModal,
    }] : [{
      label: 'Cancel',
      ui: 'shaded',
      onClick: this.closeModal,
    }, {
      label: 'Submit',
      ui: 'citrus',
      disabled: isSubmitting,
      onClick: handleSubmit
    }]

    return (
      <Modal visible={visible}
             title='Forgot Password'
             containerWidth={480}
             footerButtons={buttons}
             onClose={this.closeModal}>
        {showFeedback ?
          <div className='message'>
            If an account with that email address exists in our system, then a reset email will be sent with further
            instructions.
          </div> :
          <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            {this.renderError(errors)}
            <FormikTextInput
              id='email'
              placeholder='Email'
              type='text'
              errorTooltip={false}
              {...this.props}/>
          </form>
        }
      </Modal>
    )
  };
}

const ForgotPasswordModal = withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    email: ''
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address format!')
      .required('Email is required!'),
  }),
  handleSubmit: (values, { props, setStatus, setErrors, setSubmitting }) => {
    const { email } = values
    const { legacyAuth } = props

    legacyAuth.forgotPassword(email).then(() => {
      setStatus({ success: true })
    }).catch((error) => {
      setSubmitting(false)
      const errorMessage = error && error.message
      if (errorMessage === 'Profile is locked') {
        setErrors({ serverError: 'Your account is temporarily locked. You cannot reset your password at this time.' })
      } else {
        setErrors({ serverError: 'Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.' })
      }
    })
  },
  displayName: 'ForgotPasswordForm', // helps with debugging (React DevTools)
})(_ForgotPasswordModal)

export default ForgotPasswordModal

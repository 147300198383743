import type { AuthProviderProps } from "react-oidc-context";
import { config } from "../config";
import { LOCAL_AUTH_MODE, oAuthHelper } from "../services/oAuth.service";
import { WebStorageStateStore } from "oidc-client-ts";

export const authStoragekeyPrefix = "Q4_";
export const authRedirectURLKey = `${authStoragekeyPrefix}authRedirectURL`;
export const authSilentRequestTimeoutInSeconds = 30;

const authService = {
  DesktopAuth0: {
    audience: config.auth0Audience,
    clientId: config.auth0ClientId,
    authPath: "/authorize",
    logoutPath: "/logout",
    scope: "openid profile email offline_access",
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin + "/login",
    resource: "",
  },
  DesktopQ4idp: {
    audience: config.idp.audience,
    clientId: config.idp.clientId,
    authPath: "/oauth/auth",
    logoutPath: "/oauth/session/end",
    scope: "openid profile email",
    redirectUri: window.location.origin.replace('www.', ''),
    postLogoutRedirectUri: window.location.origin.replace('www.', '') + "/login",
    resource: config.idp.resource,
  },
  PlatformAuth0: {
    audience: config.platformAuth0Audience,
    clientId: config.platformAuth0ClientId,
    authPath: "/authorize",
    logoutPath: "/logout",
    scope: "openid profile email offline_access",
    redirectUri: `${window.location.origin}/email`,
    postLogoutRedirectUri: window.location.origin + "/login",
    resource: "",
  },
  PlatformQ4Idp: {
    audience: config.platformQ4IdpAudience,
    clientId: config.platformQ4IdpClientId,
    authPath: "/oauth/auth",
    logoutPath: "/oauth/session/end",
    scope: "openid profile email",
    redirectUri: window.location.origin.replace('www.', ''),
    postLogoutRedirectUri: window.location.origin.replace('www.', '') + "/login",
    resource: config.idp.resource,
  },
};

export const getOAuthConfig = (target: string): AuthProviderProps => {
  if (!oAuthHelper.useOAuthSDK()) return {};

  const baseUrl = target === LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP
    ? config.idp.baseUrl ?? ""
    : `https://${config.auth0Domain}`;

  switch (target) {
    case LOCAL_AUTH_MODE.VALUE.DESKTOP_AUTHO:
      return {
        authority: baseUrl,
        client_id: authService.DesktopAuth0?.clientId ?? "",
        extraQueryParams: {
          audience: authService.DesktopAuth0?.audience ?? "",
          isDesktop: 'true',
        },
        metadata: {
          token_endpoint: `${baseUrl}/oauth/token`,
          end_session_endpoint: `${baseUrl}${authService.DesktopAuth0?.logoutPath}`,
          authorization_endpoint: `${baseUrl}${authService.DesktopAuth0?.authPath}`,
        },
        post_logout_redirect_uri: authService.DesktopAuth0?.postLogoutRedirectUri,
        redirect_uri: authService.DesktopAuth0?.redirectUri,
        userStore: new WebStorageStateStore({ store: window.localStorage, prefix: "auth_d_auth0_" })
      }
    case LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP: 
      return {
        authority: baseUrl,
        client_id: authService.DesktopQ4idp?.clientId ?? "",
        extraQueryParams: {
          audience: authService.DesktopQ4idp?.audience ?? "",
        },
        metadata: {
          token_endpoint: `${baseUrl}/oauth/token`,
          end_session_endpoint: `${baseUrl}${authService.DesktopQ4idp?.logoutPath}`,
          authorization_endpoint: `${baseUrl}${authService.DesktopQ4idp?.authPath}`,
        },
        post_logout_redirect_uri: authService.DesktopQ4idp?.postLogoutRedirectUri,
        redirect_uri: authService.DesktopQ4idp?.redirectUri,
        resource: authService.DesktopQ4idp?.resource,
      }
    case LOCAL_AUTH_MODE.VALUE.PLATFORM_AUTH0:
      return {
        authority: baseUrl,
        client_id: authService.PlatformAuth0?.clientId ?? "",
        extraQueryParams: {
          audience: authService.PlatformAuth0?.audience ?? "",
        },
        metadata: {
          token_endpoint: `${baseUrl}/oauth/token`,
          end_session_endpoint: `${baseUrl}${authService.PlatformAuth0?.logoutPath}`,
          authorization_endpoint: `${baseUrl}${authService.PlatformAuth0?.authPath}`,
        },
        post_logout_redirect_uri: authService.PlatformAuth0?.postLogoutRedirectUri,
        redirect_uri: authService.PlatformAuth0?.redirectUri,
        userStore: new WebStorageStateStore({ store: window.localStorage, prefix: "auth_p_auth0_" })
      }
    case LOCAL_AUTH_MODE.VALUE.PLATFORM_Q4IDP:
      return {
        authority: baseUrl,
        client_id: authService.PlatformAuth0?.clientId ?? "",
        extraQueryParams: {
          audience: authService.PlatformAuth0?.audience ?? "",
          isDesktop: 'true',
        },
        metadata: {
          token_endpoint: `${baseUrl}/oauth/token`,
          end_session_endpoint: `${baseUrl}${authService.PlatformAuth0?.logoutPath}`,
          authorization_endpoint: `${baseUrl}${authService.PlatformAuth0?.authPath}`,
        },
        post_logout_redirect_uri: authService.PlatformAuth0?.postLogoutRedirectUri,
        redirect_uri: authService.PlatformAuth0?.redirectUri,
      }
    default: 
      return {}
  }
};

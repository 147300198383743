import { config } from '../config'
import { push } from 'connected-react-router'
import { saveAs } from 'file-saver'
import { ACCESS_TOKEN, ACCESS_TOKEN_OAUTH } from '../services/legacyAuth.service'
import { oAuthHelper } from '../services/oAuth.service'
export const DOWNLOAD_API = 'DOWNLOAD_API'
export const DOWNLOAD_TYPE = { GET: 'GET', POST: 'POST' }

/**
 * Download file
 * @param endpoint
 * @param authenticatedRequest
 * @param payload
 * @param options
 * @param method
 * @param token
 * @return {Promise.<TResult>}
 */
const downloadApi = async (endpoint, authenticatedRequest, payload, options, method ) => {

  const token = oAuthHelper.useOAuthSDK()
  ? localStorage.getItem(ACCESS_TOKEN_OAUTH) 
  : localStorage.getItem(ACCESS_TOKEN)

  if (authenticatedRequest) {
    options.headers.Authorization = `Bearer ${token}`
  }

  // apply no-caching headers
  Object.assign(options.headers, {
    'Cache-control': 'no-cache, no-store, must-revalidate, max-age=-1, private',
    Pragma: 'no-cache',
    Expiries: '-1'
  })

  const url = config.apiUrl + endpoint

  if (!payload.file || !payload.file.name || !payload.file.type) {
    throw new Error('No file provided')
  }

  if (DOWNLOAD_TYPE.POST === method) {
    options.method = method
    options.body = JSON.stringify(payload.params || payload)
  }

  const response = await fetch(url, options)
  const blob = await response.blob()

  return saveAs(blob, `${payload.file.name}.${payload.file.type}`)
}

export default () => (next) => (action) => {
  const downloadAPI = action[DOWNLOAD_API]

  if (typeof downloadAPI === 'undefined') {
    return next(action)
  }

  let { endpoint, types, payload, authenticatedRequest, options, method } = downloadAPI

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (typeof authenticatedRequest === 'undefined') {
    authenticatedRequest = true
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  const [requestType, successType, failureType] = types

  function actionWith (data) {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[DOWNLOAD_API]
    return finalAction
  }

  function doError (data) {
    if (data.status === 401) {
      if (data?.error === 'Organization mismatch') { // Force reload to clean up browser requests cache
        window.location.href = '/logout?headless=true'
        return
      }

      return Promise.all([next(data), next(push('/logout'))])
    } else {
      return next(data)
    }
  }

  next(actionWith({ type: requestType, payload }))

  return downloadApi(endpoint, authenticatedRequest, payload, options, method).then(
    (payload) => (next)(actionWith({
      payload,
      authenticatedRequest,
      type: successType
    }))
  ).catch((error) => (doError)(actionWith({
    type: failureType,
    error: error.message || 'Error!',
    status: error.status
  })))
}

import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { ACCESS_TOKEN } from '../../../services/legacyAuth.service'

// actions
import { useTearsheetRequestQuery, useProfileQuery, useSecurityQuery } from '../hook'

// components
import Cover from './cover/cover.component'
import { Tearsheet } from '../../../components'
import ItineraryPage from '../../../pages/activity/itinerary/itinerary.page'

// utils
import { getOwnershipType, ACTIVITY_TYPE, isTradingTicker, isLocalhostApi, LOCALIZATION_KEY, setLocalizationLocalStorage } from '../../../utils'
import { get } from 'lodash'
import { getSecurity } from '../../../actions'

import { launchDarklyHelper, LD_FEATURE_FLAGS } from '../../../services/launchDarkly.service'

const { ROADSHOW, CONFERENCE } = ACTIVITY_TYPE
const GET_SECURITY_SUCCESS = "GET_SECURITY_SUCCESS"

/**
 * Briefing Book Export Page
 * @param props
 */
function BriefingBookExportPage (props) {
  const [securities, setSecurities ] = useState({})

  const { location, getSecurity } = props
  const queryParams = queryString.parse(get(location, 'search', ''))
  const tickerId = get(queryParams, 'tickerId', null)
  const requestId = get(queryParams, 'requestId')
  const token = get(queryParams, 'token')
  const legacySecurityId = get(queryParams, 'legacySecurityId', null) // TODO: deprecate legacySecurityId when completely switched to q4tickerId
  const region = get(queryParams, 'region')
  const useLocalizedDateFormat = get(queryParams, 'useLocalizedDateFormat')
  const currencyCode = get(queryParams, 'currencyCode')

  // FYI - Any child components using the getLocalizedCurrency() util will be using the following cookie.
  // It is provided initally from the UI in src/components/briefingBook/export/export.container.js
  // and routed through `generateBriefingBook` on desktop-api-graphql, through desktop-sqs, desktop-api-worker-serverless
  // then sent here - it is a separate token from the one provided by the UI, made specifially for serverside exports. 
  setLocalizationLocalStorage({
    region: region,
    currency: currencyCode
  })

  document.cookie = `${LOCALIZATION_KEY}=${JSON.stringify({
    region: region,
    useLocalizedDateFormat: useLocalizedDateFormat
  })}; path=/; ${!isLocalhostApi() ? 'domain=.q4desktop.com;':''}`
  
  const allLdFlags = {}
  allLdFlags[LD_FEATURE_FLAGS.USE_LOCALIZED_DATE_FORMAT] = useLocalizedDateFormat
  launchDarklyHelper.setLocalFlags(allLdFlags)

  localStorage.setItem(ACCESS_TOKEN, token)
  document.body.classList.add('briefing-book-export-page')

  const { error, data } = useTearsheetRequestQuery({ variables: { id: requestId } })
  const { data: profile } = useProfileQuery()
  
  const me = get(profile, 'me', {})
  const request = get(data, 'tearsheetRequest.items[0]', {})
  const { id, reference, title, note, itinerary, template } = request

  const subscription = getOwnershipType(me)
  const tickers = get(me, 'organizationConnection.tickers', [])
  const ticker = tickers.find((ticker) => ticker.legacySecurityId === legacySecurityId)
  const security = isTradingTicker(tickerId) ? get(securities, 'security.items[0]') : securities
  const entities = get(reference, 'referenceConnection.items[0].entity', [])
  const activity = get(reference, 'referenceConnection.items[0].activityConnection.items[0]', {}) || {}
  const isItinerary = itinerary && [ROADSHOW, CONFERENCE].includes(activity.category)
  const organization = get(me, 'organizationConnection')
  
  // NonTrading Fix to return necessary data from MongoDB for Industry Top Buys/Sells
  const { data: securityByTickerId } = isTradingTicker(tickerId)
    ? useSecurityQuery({ variables: { tickerId } })
    : {}
  
  // getSecurity Redux dispatch function that pulls data from Mongo by legacySecurityId
  const getSecurityByLegacyId = (legacyId) => {
    const res = getSecurity({ entityId: legacyId })
    res.then(item => {
      const { type, payload = {} } = item
      if (type === GET_SECURITY_SUCCESS && payload) {
        setSecurities(payload)
      }
    })
  }

  useEffect(() => {
    isTradingTicker(tickerId)
      ? setSecurities(securityByTickerId)
      : getSecurityByLegacyId(legacySecurityId)
  }, [tickerId, securityByTickerId, legacySecurityId])
  // End of NonTrading Fix

  if (error || !id || !ticker) {
    return null
  }

  return (
    <>
      {!!((title && title.length) || (note && note.length)) &&
        <Cover
          profile={me}
          title={title}
          note={note}
        />}
      {isItinerary && activity.id && <ItineraryPage activityId={activity.id} />}
      {(entities || []).map((entity) => {
        const { entityId, entityType, institutionId } = (entity || {})
        return (
          (entityId && entityType)
            ? <Tearsheet
                key={entityId}
                entityId={entityId}
                entityType={entityType}
                institutionId={institutionId}
                ticker={ticker}
                security={security}
                subscription={subscription}
                selectedCards={get(template, 'items', [])}
                entities={entities}
                organization={organization}
              />
            : null
        )
      })}
    </>
  )
}

const mapStateToProps = (state) => ({
  security: get(state, 'security')
})

const mapDispatchToProps = (dispatch) => ({
  getSecurity: bindActionCreators(getSecurity, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BriefingBookExportPage))

import { ACCESS_TOKEN_OAUTH } from "./legacyAuth.service"

let getAccessTokenSilently = null
let useOAuthSDK = false

export const LOCAL_AUTH_MODE = {
  KEY: 'localAuthMode',
  VALUE: {
    DESKTOP_LEGACY: 'DESKTOP_LEGACY',
    DESKTOP_AUTHO: 'DESKTOP_AUTHO',
    DESKTOP_Q4IDP: 'DESKTOP_Q4IDP',
    PLATFORM_AUTH0: 'PLATFORM_AUTH0',
    PLATFORM_Q4IDP: 'PLATFORM_Q4IDP',
  }
}

export const USED_LOCAL_AUTH_MODE = {
  KEY: 'usedLocalAuthMode'
}

export const oAuthHelper = {
  useOAuthSDK: () => useOAuthSDK,
  setUseOAuthSDK: (flag) => { useOAuthSDK = flag },
  getAccessTokenSilently: () => {
    return () => { localStorage.getItem(ACCESS_TOKEN_OAUTH) }
  },
}

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { ThrottledFetch } from '../utils/http/throttledFetch.util'

import { config } from '../config'
import { ACCESS_TOKEN, ACCESS_TOKEN_OAUTH } from '../services/legacyAuth.service'
import { oAuthHelper } from '../services/oAuth.service'
import { any } from 'prop-types'

const cache = new InMemoryCache()

const defaultOptions = {
  watchQuery: {
    notifyOnNetworkStatusChange: any
  },
  query: {},
  mutate: {
    errorPolicy: 'all'
  }
}

const cleanTypename = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value)
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }
  return forward(operation)
})

const httpLink = new HttpLink({
  uri: config.graphqlUrl,
  credentials: 'include',
})

const withToken = setContext(async request => {

  const token = oAuthHelper.useOAuthSDK()
    ? localStorage.getItem(ACCESS_TOKEN_OAUTH)
    : localStorage.getItem(ACCESS_TOKEN)

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const resetToken = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    //currently unused
  }
})

const authFlowLink = withToken.concat(resetToken);

const link = authFlowLink.concat(httpLink);


export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    cleanTypename,
    resetToken,
    authFlowLink,
    link
  ]),
  defaultOptions,
  queryDeduplication: false, // Disabled due to high frequent requests, such as query-on-filter-change forms
  resolvers: {}
})

const throttledFetch = new ThrottledFetch()

const exportHttpLink = new HttpLink({
  uri: config.graphqlUrl,
  credentials: 'include',
  fetch: (input, init) => throttledFetch.fetch(input, init)
})

const exportLink = authFlowLink.concat(exportHttpLink);
export const exportClient = new ApolloClient({
  cache,
  link: ApolloLink.from([
    cleanTypename,
    resetToken,
    authFlowLink,
    exportLink
  ]),
  defaultOptions,
  resolvers: {}
})

export default client

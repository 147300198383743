import { config } from '../config'
import { SECURITY_ID } from '../utils/security.util'
import { LOCAL_AUTH_MODE, USED_LOCAL_AUTH_MODE } from './oAuth.service'

export const ACCESS_TOKEN_OAUTH = 'access_token_oauth'
export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'
export const EXPIRE_TIME = 'expires_at'

export default class LegacyAuthService {
  tokenRenewalTimeout

  constructor ({ onTokenChange }) {
    const isLoggedIn = this.isLoggedIn()
    const access_token = localStorage.getItem(ACCESS_TOKEN)

    this.onTokenChange = onTokenChange || (() => {})
    this.scheduleRenewal()

    if (isLoggedIn && access_token) {
      this.onTokenChange(access_token)
    }
  }

  isLoggedIn = () => {
    // Check whether the current time is past the access token's expiry time
    const expiresAt = localStorage.getItem(EXPIRE_TIME) * 1000
    return new Date().getTime() < expiresAt
  }

  login = (user, password) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user, password, product: 'desktop' }),
      credentials: 'include'
    }

    return fetch(`${config.apiUrl}/auth`, requestOptions)
      .then(_checkStatus)
      .then((data) => {
        const token = data && data.token
        const access_token = token && token[ACCESS_TOKEN]
        // set auth credentials
        if (token) {
          this.setSession(token)
        }
        this.onTokenChange(access_token, data)

        const localAuthMode = !!localStorage.getItem(LOCAL_AUTH_MODE.KEY)
        if (localAuthMode) localStorage.setItem(USED_LOCAL_AUTH_MODE.KEY, true)

        return data
      })
  }

  logout = () => {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(EXPIRE_TIME)
    localStorage.removeItem(SECURITY_ID)
    document.cookie = `access_token=; expires=${new Date().toUTCString()}; path=/; domain=.q4desktop.com;`;
    clearTimeout(this.tokenRenewalTimeout)
    this.onTokenChange()
  }

  refreshToken = (refreshToken) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include'
    }

    return fetch(`${config.apiUrl}/auth/refreshToken?token=${refreshToken}`, requestOptions)
      .then(_checkStatus)
      .then((response) => {
        const data = response && response.data
        const access_token = data && data[ACCESS_TOKEN]
        if (data) {
          this.setSession(data)
        }
        this.onTokenChange(access_token)
        return response
      })
  }

  forgotPassword = (email) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, product: 'desktop' })
    }

    return fetch(`${config.apiUrl}/profile/forgot`, requestOptions)
      .then(_checkStatus)
      .then((data) => data)
  }

  /**
   * Change password
   * @param oldPass
   * @param newPass
   * @returns {Promise<Response>}
   */
  passwordReset = (token, password) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, password })
    }

    return fetch(`${config.apiUrl}/profile/reset`, requestOptions)
      .then(_checkStatus)
      .then((data) => {
        if (!data.success) {
          return Promise.reject(data)
        }
        return data
      })
  }

  /**
   * Change password
   * @param oldPass
   * @param newPass
   * @returns {Promise<Response>}
   */
  changePassword = (oldPass, newPass) => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      body: JSON.stringify({ oldPass, newPass, product: 'desktop' })
    }

    return fetch(`${config.apiUrl}/profile/changePassword`, requestOptions)
      .then(_checkStatus)
      .then((data) => {
        if (!data.success) {
          return Promise.reject(data)
        }

        return data
      })
  }

  scheduleRenewal = () => {
    const expiresAt = localStorage.getItem(EXPIRE_TIME) * 1000
    const delay = (expiresAt - Date.now()) - 60 * 1000
    const refreshToken = localStorage.getItem(REFRESH_TOKEN)

    if (!refreshToken) {
      return
    }

    // token already expired
    if (delay <= 0) {
      this.refreshToken(refreshToken)
      return
    }

    // schedule token renewal
    this.tokenRenewalTimeout = setTimeout(() => {
      this.refreshToken(refreshToken)
    }, delay)
  }

  setSession = (authResult) => {
    // set jwt auth response
    localStorage.setItem(ACCESS_TOKEN, authResult[ACCESS_TOKEN])
    localStorage.setItem(REFRESH_TOKEN, authResult[REFRESH_TOKEN])
    localStorage.setItem(EXPIRE_TIME, authResult['expire_token'])

    // schedule a token renewal
    this.scheduleRenewal()
  }
}

function _checkStatus (response) {
  if (response && response.status !== 200) {
    return response.json().then((res) => Promise.reject(res))
  }
  return response.json()
}